import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';
import { MapService } from 'src/app/new-map/core/map.service';
import { MobileBrowserChecker } from 'src/helpers';
import { StationInfoDrawerData } from 'src/models';
import { SettingDrawerComponent } from 'src/app/station/setting-drawer/setting-drawer.component';
import { MatButtonModule } from '@angular/material/button';
import { ConnectionInfoDrawerComponent } from 'src/app/shared/connection-info-drawer/connection-info-drawer.component';
import { ContainerInfoDrawerComponent } from 'src/app/shared/container-info-drawer/container-info-drawer.component';
import { StationInfoDrawerComponent } from 'src/app/shared/station-info-drawer/station-info-drawer.component';
import { StationGroupInfoDrawerComponent } from 'src/app/shared/station-group-info-drawer/station-group-info-drawer.component';

/**
 * Component for info drawer.
 */
@Component({
  selector: 'app-info-drawer',
  templateUrl: './info-drawer.component.html',
  styleUrls: ['./info-drawer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ContainerInfoDrawerComponent,
    StationInfoDrawerComponent,
    SettingDrawerComponent,
    MatButtonModule,
    ConnectionInfoDrawerComponent,
    StationGroupInfoDrawerComponent,
  ],
})
export class InfoDrawerComponent implements OnInit, OnDestroy {
  /** Subject for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Set to true if is opened from a different view than the station. */
  @Input() isOutsideView = false;

  /** Set to true, if rendering from the map folder. */
  @Input() isUsedFromMap = false;

  /** Set to true, if rendering from admin module. */
  @Input() isAdminView = false;

  /** Whether the called info-drawer is documentInfo type or stationInfo. */
  drawerMode:
    | ''
    | 'stationInfo'
    | 'containerInfo'
    | 'history'
    | 'fieldSetting'
    | 'stationGroupInfo'
    | 'connectionInfo' = '';

  /** Whether the station drawer is opened from map or not. */
  openedFromMap = false;

  /** The centering progress is active in station or station group. */
  centerActive = false;

  constructor(
    private sidenavDrawerService: SidenavDrawerService,
    private mapService: MapService,
    public mobileBrowserChecker: MobileBrowserChecker,
  ) {}

  /**
   * Check the URL path and show/hide the navigation.
   */
  ngOnInit(): void {
    this.sidenavDrawerService.drawerContext$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (
          data === 'containerInfo' ||
          data === 'stationInfo' ||
          data === 'history' ||
          data === 'fieldSetting' ||
          data === 'stationGroupInfo' ||
          data === 'connectionInfo'
        ) {
          this.drawerMode = data;
        }
      });
    this.sidenavDrawerService.drawerData$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        const dataDrawer = data as StationInfoDrawerData;
        if (dataDrawer) {
          this.openedFromMap = dataDrawer.openedFromMap;
        }
      });
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    /** Clear drawerContext in a general way to avoid problems when opening one drawer or another. */
    this.sidenavDrawerService.drawerContext$.next('');
  }

  /**
   * Toggles the open state for drawer mode.
   *
   */
  async toggleDrawer(): Promise<void> {
    this.mapService.mapHelper.isDrawerOpened$.next(false);
    await this.sidenavDrawerService.closeDrawer();
    this.drawerMode = '';
  }
}
